import Headroom from "headroom.js";
import SmoothScroll from "smooth-scroll";
import fslightbox from "fslightbox";
import vhCheck from "vh-check";
import Swiper from "swiper";
import { set } from "lodash";

const test = vhCheck({
    cssVarName: "vh-offset"
});

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true
});

const slideDown = element =>
    (element.style.height = `${element.scrollHeight}px`);
const slideUp = elements =>
    elements.forEach(item => {
        item.style.height = "0px";
    });
let slideDownItems = document.querySelectorAll(".js-slideDown");
let slideUpItems = document.querySelectorAll(".section--hide");

slideDownItems.forEach(item => {
    item.addEventListener("click", function() {
        if (item.classList.contains("js-slideMultiple")) {
            slideUp(slideUpItems);
            setTimeout(() => {
                slideDown(document.querySelector(this.getAttribute("data-id")));
            }, 1000);
        } else {
            slideDown(document.querySelector(this.getAttribute("href")));
        }
    });
});

document.addEventListener("click", function(e) {
    if (e.target && e.target.classList.contains("form-status")) {
        document.querySelector(".form-status").classList.add("hide");
    }
});

// Dropdown menu in burger
let burgerMenuDropdownParentLink = document.querySelector(
    "#burger-menu .header-nav__item--dropdown > a"
);
let burgerMenuDropdown = document.querySelector(
    "#burger-menu .header-nav__item--dropdown > .menu-list__inner--dropdown"
);
let burgerMenuDropdownLinks = document.querySelectorAll(
    "#burger-menu .menu-list__inner--dropdown a"
);

burgerMenuDropdownParentLink.addEventListener("click", function(e) {
    e.preventDefault();
    burgerMenuDropdown.classList.toggle("show");
});

burgerMenuDropdownLinks.forEach(link => {
    link.addEventListener("click", function(e) {
        burgerMenu.classList.remove("open");
        burgerMenuOpen.classList.remove("open");
        burgerMenuOverlay.classList.remove("open");
    });
});

//BURGER MENU
const header = document.getElementById("header-wrapper");
const burgerMenu = document.getElementById("burger-menu");
const burgerMenuOpen = document.getElementById("burger-menu-open");
const burgerMenuOverlay = document.getElementById("burger-overlay");
const headroom = new Headroom(header, {
    offset: document.getElementById("header-wrapper").offsetHeight / 2
});
headroom.init();

burgerMenuOpen.addEventListener("click", () => {
    burgerMenu.classList.toggle("open");
    burgerMenuOpen.classList.toggle("open");
    burgerMenuOverlay.classList.toggle("open");
});

burgerMenuOverlay.addEventListener("click", () => {
    burgerMenu.classList.remove("open");
    burgerMenuOpen.classList.remove("open");
    burgerMenuOverlay.classList.remove("open");
});

//Scroll up arrow
const scrollUp = document.getElementById("up");
if (scrollUp) {
    scrollUp.addEventListener("click", function() {
        scroll.animateScroll(0);
    });
}

function scrollUpToggle() {
    if (window.pageYOffset > 100) {
        if (window.oldScroll > window.scrollY) {
            document.getElementById("up").classList.add("active");
        } else {
            document.getElementById("up").classList.remove("active");
        }
    } else {
        document.getElementById("up").classList.remove("active");
    }
    window.oldScroll = window.scrollY;
}

window.onscroll = function() {
    scrollUpToggle();
};

const parent = document.querySelector('.property-list__items.predane_nehnutelnosti_items');
const loadMoreBtn = document.querySelector('#load-more-btn-predane');

if (parent) {
    const children = parent.children;
    const firstElementsToShow = 20;
    let elementsToShowInitially = 8;
    let alreadyShownElements = 0;

    for (let i = 0; i < children.length; i++) {
        children[i].style.display = 'none';
        if (i < firstElementsToShow) {
            children[i].style.display = 'block';
            alreadyShownElements++;
        }
    }

    loadMoreBtn.addEventListener('click', () => {
        let nextShowElemetns = alreadyShownElements + elementsToShowInitially;
        for (let i = alreadyShownElements; i < nextShowElemetns; i++) {
            if (i < children.length) {
                children[i].style.display = 'block';
                alreadyShownElements++;
            }
        }
        if(alreadyShownElements == children.length){
            loadMoreBtn.style.display = 'none';
        }
    });
}
